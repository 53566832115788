















































































































































































































































































































































































































































































































































































































.container {
	background-color: #f8f8f8;
	height: 100%;
	font-family: PingFangSC-Regular;
	// height: calc(100vh + 110px);
	.content {
		background-color: #f8f8f8;
		padding-bottom: 1rem;
	}
}

.user_msg {
	background-color: #ffffff;
	margin-top: 10px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	// box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	font-family: PingFang SC;
	.msg {
		padding: 0.4rem 0.2rem 0.16rem 0.2rem;
		.msg-top {
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid #e8f1fe;
			padding-bottom: 0.32rem;
			.msg_left {
				// margin-left: 1.34rem;
				.img {
					width: 1.2rem;
					height: 1.2rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
			}
			.msg_right {
				margin-left: 0.6rem;
				.name {
					color: #333333;
					font-size: 0.28rem;
					font-weight: 500;
				}
				.VIP {
					display: flex;
					align-items: center;
					.img_vip {
						// width: 0.24rem;
						// height: 0.24rem;
						img {
							width: 0.252399rem;
							height: 0.2218rem;
						}
					}
					.vip_number {
						font-family: 'haose';
						margin-left: 0.1rem;
						font-size: 0.28rem;
						color: #2E62FF;
					}
				}
				.type {
					margin-top: 0.1rem;
					color: #2E62FF;
					background-color: rgba(46, 98, 255, 0.1);
					padding: 0.12rem 0.12rem 0.1rem 0.12rem;
					font-size: 0.24rem;
					transform: scale(0.91);
					text-align: center;
					font-weight: 600;
				}
			}
		}
		.msg-bottom {
			margin-top: 0.16rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.28rem;
			color: #5899f6;
			text-align: center;
			.msg-bottom-left {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.12rem 0;
				border-right: 1px solid #e8f1fe;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #6f7a8b;
					font-size: 0.24rem;
					font-weight: 600;
				}
			}
			.msg-bottom-center {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.1rem 0;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.28rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #666666;
					font-size: 0.2rem;
					font-weight: 600;
				}
			}
			.msg-bottom-right {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.1rem 0;
				border-left: 1px solid #e8f1fe;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #6f7a8b;
					font-size: 0.24rem;
					font-weight: 600;
				}
			}
		}
	}
	.shuju {
		padding: 0.32rem 0.64rem 0.12rem 0.64rem;
		.list1 {
			display: flex;
			align-items: center;
			margin-bottom: 0.2rem;
			justify-content: space-between;
			.top_left {
				width: 40%;
				text-align: center;
			}
			.top_right {
				width: 33%;
				text-align: center;
			}
			.txt {
				color: #6f7a8b;
				font-size: 0.24rem;
			}
			.left_num {
				font-family: PingFangSC-Medium;
				color: #333;
				font-size: 0.32rem;
				font-weight: 600;
			}
			.right_num {
				font-family: PingFangSC-Medium;
				color: #32bf88;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	}
	.list_head {
		margin: 0 0.2rem;
		padding: 0.3rem 0 0 0;
		display: flex;
		justify-content: space-between;
		border-bottom: 0.02rem solid #d3d9de;
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			.top_txt {
				font-size: 0.28rem;
				color: #8b9fad;
				font-weight: 500;
			}
			.active_bottom {
				margin-top: 0.08rem;
				width: 0.6rem;
				height: 0.06rem;
				background-color: #ffffff;
			}
			.active_txt {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-weight: 600;
			}
			.active_kuai {
				background-color: #2E62FF;
			}
			&:first-child {
				margin-left: 0.56rem;
			}
			&:last-child {
				margin-right: 0.56rem;
			}
		}
	}
	.list {
		margin: 0 0.6rem;
		.list_item {
			padding: 0.4rem 0 0.2rem 0;
			width: 100%;
			border-bottom: 0.01rem solid rgba(211, 217, 222, 0.49);
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.28rem;
			color: #333333;
			font-weight: 500;
		}
		// .list_item:last-child {
		// 	padding-bottom: 0.5rem;
		// }
	}
	// .item {
	// 	margin-bottom: 0.2rem;
	// }
	.list1_top {
		padding: 0 0.2rem;
		margin: 0 0.2rem;
		margin-top: 0.39rem;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-top-right-radius: 0.16rem;
		border-top-left-radius: 0.16rem;
		.list1_top_item {
			// padding: 0.2rem;
			padding: 10px 0;
			display: flex;
			align-items: center;
			.item_name {
				font-family: PingFangSC-Medium;
				font-size: 0.28rem;
				font-weight: 600;
				color: #333300;
			}
			.item_name1 {
				font-size: 0.24rem;
				color: #6f7a8b;
			}
			.item_time {
				color: #6f7a8b;
				font-size: 12px;
			}
			.item_zuoduo {
				span {
					font-size: 0.24rem;
					transform: scale(0.83);
					padding: 0 0.1rem;
					line-height: 0.36rem;
					background: #f1f7ff;
					border-radius: 0.04rem;
					display: inline-block;
					margin-left: 0.05rem;
					&.gray {
						color: #9b9b9b;
						background: #f5f5f5;
					}
					&.gray1 {
						padding: 0.02rem 0.13rem;
						border: none;
						color: #f5465d;
						background: rgba(245, 70, 93, 0.1);
					}
					&.gray2 {
						padding: 0.02rem 0.13rem;
						border: none;
						color: #32bf88;
						background: rgba(50, 191, 136, 0.09);
					}
				}
			}
			.item_right {
				.gray1 {
					color: #2E62FF;
					font-size: 0.28rem;
					text-align: center;
				}
			}
		}
	}
	.dixian_kuai {
		margin: 0 0.2rem;
		background-color: #fcfcfc;
		.dixian {
			margin: 0 auto;
			width: 6.7rem;
			height: 0.02rem;
			background-color: rgba(88, 153, 246, 0.08);
		}
	}
	.list1_bottom {
		margin: 0 0.2rem;
		padding: 0.2rem 0;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-bottom-right-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
		.listQuan_2 {
			// margin: 0.1rem 0.25rem 0.1rem 0.15rem;
			// padding: 12px 0 16px 0;
			// display: flex;
			// justify-content: space-between;
			.listQuan_2_item {
				text-align: center;
				.label {
					font-size: 0.24rem;
					color: #6f7a8b;
				}
				.value {
					font-family: PingFangSC-Medium;
					color: #333333;
					font-size: 14px;
					font-weight: bold;
				}
				.green {
					color: #32bf88;
				}
				.red {
					color: #f5465d;
				}
			}
			.listQuan_2_name {
				color: #373737;
				font-size: 0.36rem;
				font-family: PingFang SC;
				display: flex;
				justify-content: space-between;
				height: 0.8rem;
				align-items: center;
			}
			.listQuan_2_value {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				.listQuan_2_value_left {
					display: flex;
					align-items: center;
					.chicang {
						font-size: 0.36rem;
						font-weight: 550;
						margin-right: 0.1rem;
					}
					.bucang {
						font-size: 0.22rem;
						color: #373737;
					}
				}
				.listQuan_2_value_right {
					display: flex;
					align-items: center;
					.fiyin {
						font-size: 0.36rem;
						font-weight: 550;
						margin-left: 0.1rem;
					}
					.fudu {
						font-size: 0.24rem;
					}
					.green {
						color: #07ad91;
					}
					.red {
						color: #cf4e65;
					}
				}
			}
		}
	}
	.list1_bottom1 {
		margin: 0 0.2rem;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-bottom-right-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
		.listQuan_2 {
			margin: 0.2rem 1rem;
			.listQuan_2_item {
				text-align: left;
				.label {
					font-size: 0.24rem;
					color: #6f7a8b;
				}
				.value {
					font-family: PingFangSC-Medium;
					color: #333333;
					font-size: 14px;
					font-weight: 600;
				}
				.green {
					color: #32bf88;
				}
				.red {
					color: #f5465d;
				}
			}
			.listQuan_2_name {
				color: #373737;
				font-size: 0.36rem;
				font-family: PingFang SC;
				display: flex;
				justify-content: space-between;
				height: 0.8rem;
				align-items: center;
			}
			.listQuan_2_value {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				.listQuan_2_value_left {
					display: flex;
					align-items: center;
					.chicang {
						font-size: 0.36rem;
						font-weight: 550;
						margin-right: 0.1rem;
					}
					.bucang {
						font-size: 0.22rem;
						color: #373737;
					}
				}
				.listQuan_2_value_right {
					display: flex;
					align-items: center;
					.fiyin {
						font-size: 0.36rem;
						font-weight: 550;
						margin-left: 0.1rem;
					}
					.fudu {
						font-size: 0.24rem;
					}
					.green {
						color: #07ad91;
					}
					.red {
						color: #cf4e65;
					}
				}
			}
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	.button {
		margin: 0.14rem 0.3rem 0.14rem 0.28rem;
		width: 6.9rem;
		height: 0.88rem;
		line-height: 0.88rem;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		color: #FFFFFF;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.12rem;
		font-weight: 500;
	}
}
.tan {
	width: 5.6rem;
	height: 2.94rem;
	position: relative;
	.tan_no_txt {
		padding: 0.69rem 0.82rem 0 0.6rem;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		font-weight: 500;
	}
	.tan_no_txt1 {
		padding: 0.69rem 0.82rem 0 0.72rem;
		color: #333333;
		font-size: 0.24rem;
		text-align: left;
		font-weight: 500;
		color: #333333;
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		color: #333333;
		font-size: 0.16rem;
		transform: scale(0.75);
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		text-align: left;
		.right {
			color: #2E62FF;
		}
	}
	.input {
		padding: 0.66rem 0.7rem 0 0.72rem;
		color: #333333;
		font-weight: bold;
		input {
			width: 100%;
			border: none;
			border-bottom: 1px solid #d3d9de;
			padding: 0.04rem 0;
			font-size: 0.32rem;
		}
	}
	.tan_no_button {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 0.8rem;
		line-height: 0.8rem;
		text-align: center;
		border-top: 0.01rem solid #D3D9DE;
		.yes {
			flex: 1;
		    color: #2E62FF;
			font-size: 0.28rem;
			border-right: 0.01rem solid #D3D9DE;
		}
		.no {
			flex: 1;
		    color: #2E62FF;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;